<template>
  <div id="news">
    <div
      class="new_goods_title_hot"
      style="display: flex; justify-content: center; width: 100%"
    >
      <span style="font-size: 40px; line-height: 28px; font-weight: bold" v-if="lange=='中文'">新闻列表</span>
      <span style="font-size: 40px; line-height: 28px; font-weight: bold" v-else>News List</span>
    </div>
    <div style="display: flex; width: 80%; margin: 0 auto; flex-wrap: wrap">
      <div
        v-for="(item, index) in list"
        :key="index"
        style="width: 31%; margin: 2% 1%"
        @click="goDetail(item)"
      >
        <div><img :src="item.url" style="width: 100%" /></div>
        <div style="font-size: 18px; font-weight: 600; line-height: 33px">
          {{ item.title }}
        </div>
        <div style="font-size: 15px; color: #8c8c8c; line-height: 25px">
          {{ item.subtitle }}
        </div>
        <div style="font-size: 15px; color: #8c8c8c; line-height: 25px">
          {{ item.createTime }}
        </div>
      </div>
    </div>
    <div style="display: flex; justify-content: center" @click="goToHotGood()">
        <div class="btnMore" v-if="lange == '中文'">查看更多</div>
        <div class="btnMore" v-else>search for more</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { dcShoppingMallCommodityQueryNews } from "@/api/Introduction/index.js";
export default {
  name: "",
  data() {
    return {
      currentPage3: 1,
      showList: true,

      articleList: [],
      searchData: {
        pageNo: 1,
        pageSize: 10,
        type: 1,
      },
      list: [],
      total: 0,
      number: 1,
      loading: false, //防重复点击
    };
  },
  computed: {
    ...mapGetters({
      lange: "user/lange",
      organizationName: "user/organizationName",
      priceChangeList: "user/priceChangeList",
    }),
  },
  created() {
    dcShoppingMallCommodityQueryNews({ pageSize: 6, pageNum: 1 }).then((res) => {
      this.list = res.data.list;
      this.list.forEach((item) => {
        item.url = this.$locationUrl + "file/" + item.imageUrl;
      });
    });
  },
  mounted() {},
  watch: {
    $route(to, from) {
      var type = this.$route.query.type;
      if (type) {
        this.searchData.type = type;
      }
      if (to.name == "News") {
        this.showList = true;
        this.getList();
      } else {
        this.showList = false;
      }
    },
  },
  methods: {
    handleCurrentChange() {},
    handleSizeChange() {},
    goToHotGood() {
      this.number = this.number * 1 + 1;
      dcShoppingMallCommodityQueryNews({ pageSize: 6, pageNum: this.number }).then(
        (res) => {
          let list = res.data.list;
          list.forEach((item) => {
            item.url = this.$locationUrl + "file/" + item.imageUrl;
          });
          this.list = [...this.list, ...list];
        }
      );
    },
    toUrl(type) {
      this.searchData.type = type;
      this.searchData.pageNo = 1;
      var path = this.$route.path;
      if (path != "/news") {
        this.$router.push("/news?type=" + this.searchData.type);
      } else {
        this.getList();
      }
    },
    goDetail(item) {
      this.$router.push({ path: "/goods/NewDetail", query: { Id: item.newsId,brandName:this.$route.query.brandName  } });
    },
    //获取最新公告列表
    getArticleList() {
      this.$request.post({
        url: "/ReportApi/business/newlists",
        params: {
          pageNo: 1,
          pageSize: 6,
          type: 1,
        },
        success: (res) => {
          this.articleList = res[0];
        },
      });
    },
    //文章列表
    getList() {
      this.loading = true;
      this.$request.post({
        url: "/ReportApi/business/newlists",
        params: this.searchData,
        success: (res) => {
          this.list = res[0];
          this.total = res[1];
        },
        finally: () => {
          this.loading = false;
        },
      });
    },
    handleCurrentChange(val) {
      this.searchData.pageNo = val;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
#news {
  .new_goods_title_hot {
    height: 40px;

    margin-top: 40px;
    margin-bottom: 40px;

    span {
      font-size: 40px;
      line-height: 28px;

      font-weight: bold;
      font-size: 40px;
    }
  }
  .btnMore {
    line-height: 28px;
    text-align: center;
    color: black;
    border: 1px solid black;
    box-sizing: border-box;
    border-radius: 30px;
    margin: 40px auto 40px auto;
    display: block;
    line-height: 45px;
    font-size: 14px;
    font-weight: 700;
    width: 200px;
    &:hover {
      color: #fff;
      background: black;
    }
  }
  .mbx {
    line-height: 60px;
    font-size: 14px;
    color: #666;
  }
  .news_content {
    .news_left {
      float: left;
      width: 260px;
      .news_cat {
        border-radius: 6px;
        border: 1px solid #dadada;
        ul {
          li {
            line-height: 55px;
            text-align: center;
            border-bottom: 1px solid #dadada;
            font-size: 18px;
            cursor: pointer;
          }
          .red {
            color: #fff;
            background: #f80;
            border-radius: 6px 6px 0 0;
          }
        }
      }
      .system_list {
        margin-top: 40px;
        border: 1px solid #dadada;
        .title {
          line-height: 55px;
          background-color: #f2f2f2;
          color: #f80;
          text-align: center;
          font-size: 18px;
        }
        ul {
          margin-top: 8px;
          li {
            line-height: 35px;
            font-size: 14px;
            padding: 0 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            span {
              width: 5px;
              height: 5px;
              display: block;
              border-radius: 50%;
              margin-right: 8px;
              background: #f80;
              margin-top: 14px;
              float: left;
            }
          }
        }
      }
    }
    .news_right {
      float: right;
      width: 860px;
      .news_item {
        padding: 20px;
        border: 1px solid #dadada;
        border-radius: 6px;
        margin-bottom: 20px;
        .title {
          font-weight: bold;
          line-height: 35px;
          margin-bottom: 15px;
          a {
            color: #f80;
          }
        }
        .content {
          max-height: 75px;
          color: #666;
          font-size: 14px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          line-height: 25px;
        }
        .views {
          margin-top: 20px;
        }
        span {
          float: right;
          font-size: 14px;
          color: #999;
          line-height: 32px;
        }
        .btn {
          line-height: 32px;
          height: 32px;
          width: 110px;
          text-align: center;
          border-radius: 6px;
          background: #f80;
          color: #fff;
          float: right;
          margin-right: 20px;
          cursor: pointer;
        }
      }
    }
  }
  .xian {
    height: 5px;
    background: #eee;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .home_fy_block {
    margin-top: 30px;
    text-align: right;
  }
}
</style>
